<template>
  <div class="error">
    <div class="error-img">
      <img src="../../assets/img/error/404.png">
    </div>
    <div class="error-title">
      <span>抱歉，该页面已丢失</span>
    </div>
    <div class="error-desc">
      <span>请检查浏览器地址是否完整或存在多余字符</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped>
.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-title {
  font-size: 36px;
  text-align: center;
  margin: 10px 0;
  color: #3a9fd7;
}

.error-desc {
  text-align: center;
  margin: 10px 0;
  color: #3a9fd7;
}
</style>
