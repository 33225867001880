import Vue from 'vue'
import App from './app.vue'
import iView from 'view-design'
import jquery from 'jquery'

Vue.use(iView)
Vue.prototype.$jq = jquery
Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app')
